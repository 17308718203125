@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
  --bg-public-pages: #0b0c0d;
  --color-primary: #fea84b;
  --color-primary-dark: #3f2603;
  --color-primary-light: #fff1e1;
  --gradient-background-start: #016368;
  --gradient-background-end: #25393c;
  --first-color: 234, 190, 40;
  --second-color: 1, 219, 152;
  --third-color: 1, 22, 102;
  --fourth-color: 139, 9, 17;
  --fifth-color: 195, 80, 150;
  --pointer-color: 167, 115, 62;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
}
.landing-card-panel {
  background: var(--bg-public-pages);
  background: linear-gradient(var(--bg-public-pages), var(--bg-public-pages))
      padding-box,
    linear-gradient(to bottom, #273034, var(--bg-public-pages)) border-box;
  border: 2px solid transparent;

  @apply rounded-lg;
}

.pricing-card {
  @apply landing-card-panel transition-colors duration-150;
  transition: all 0.15s ease-in;
}
.pricing-card:hover {
  background: var(--bg-public-pages);
  background: linear-gradient(var(--bg-public-pages), var(--bg-public-pages))
      padding-box,
    linear-gradient(to bottom, var(--color-primary), var(--bg-public-pages))
      border-box;
}
